import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-cotizacion',
  templateUrl: './modal-cotizacion.component.html',
  styleUrls: ['./modal-cotizacion.component.scss'],
})
export class ModalCotizacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
