export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyDFiYdiqCuEGVqwB544YCCCQiC29wDelX0",
  authDomain: "central-autolote.firebaseapp.com",
  projectId: "central-autolote",
  storageBucket: "central-autolote.appspot.com",
  messagingSenderId: "596832758058",
  appId: "1:596832758058:web:0d60273c306b0ad1b51653"
  }
};
