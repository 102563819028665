import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
})
export class ContactoComponent implements OnInit {
  datos = {
    whatsapp: '83695594',
    telefono: '+505 83695594',
    facebook: 'https://www.facebook.com/Central-Autolote-110833871629251/?ref=pages_you_manage',
    instagram: 'https://www.instagram.com/centralautolote/',
    gps: '12.098793984837151, -86.23958258283423',
    correo: 'dmendoza@centralautolote.com'
  }
  constructor(private modal: ModalController,
    private iab: InAppBrowser,) { }

  ngOnInit() {
    console.log(this.datos)
  }

  cerrar(){
    this.modal.dismiss();
  }


  
  abrirFace(){
    this.iab.create(this.datos.facebook);
  }

  verEnMapa(){
    this.iab.create('https://www.google.com/maps?q=' + this.datos.gps)
  }

  abrirInsta(){
    this.iab.create(this.datos.instagram);
  }

  abrirWhats(){
    this.iab.create('https://wa.me/505' + this.datos.whatsapp);
  }
}
