import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { DatosService } from 'src/app/services/datos.service';
import { ContactoComponent } from '../contacto/contacto.component';

@Component({
  selector: 'app-sidemenu-admin',
  templateUrl: './sidemenu-admin.component.html',
  styleUrls: ['./sidemenu-admin.component.scss'],
})
export class SidemenuAdminComponent implements OnInit {
  @Input() sede;
  @Input() movil;
  datos = {
    whatsapp: '83695594',
    telefono: '+505 83695594',
    facebook: 'https://www.facebook.com/Central-Autolote-110833871629251/?ref=pages_you_manage',
    instagram: 'https://www.instagram.com/centralautolote/',
    gps: '12.098793984837151, -86.23958258283423',
    correo: 'dmendoza@centralautolote.com'
  }
  constructor(private modal: ModalController,
              public datosService: DatosService,
              private modal2: ModalController,
              private nav: NavController,) { }

  ngOnInit() {}


  cerrar(){
    this.modal.dismiss();
  }


  navAutos(){
    this.nav.navigateRoot(['autos/']);
    this.modal.dismiss();
  }

  navInicio(){
    this.nav.navigateRoot(['inicio']);
    this.modal.dismiss();
  }

  navVende(){
    this.nav.navigateRoot(['venta/']);
    this.modal.dismiss();
  }
  navCotiza(){
    this.nav.navigateRoot(['cotiza/']);
    this.modal.dismiss();
  }

  async contacto() {
    let css = '';
    if(this.movil){
      css = ''
    }
    else{
      css = '';
    }
    const modal = await this.modal2.create({
      component: ContactoComponent,
      backdropDismiss: true,
      cssClass: css,
      componentProps: {
        datos: this.datos,
      }
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
     
    }
  }


}
