import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AutosService } from 'src/app/services/autos.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Auto } from 'src/app/models/auto';
import { VendidosService } from 'src/app/services/vendidos.service';

@Component({
  selector: 'app-modal-auto',
  templateUrl: './modal-auto.component.html',
  styleUrls: ['./modal-auto.component.scss'],
})
export class ModalAutoComponent implements OnInit {

  @Input() auto: Auto;
  @Input() nuevo;
  public mensajeArchivo = 'No hay un archivo seleccionado';

  public datosFormulario = new FormData();
  public nombreArchivo = '';
  public URLPublica = '';
  public porcentaje = 0;
  public finalizado = false;
  contador = 0;

  datos = {
    whatsapp: '83695594',
    telefono: '+505 83695594',
    facebook: 'https://www.facebook.com/Central-Autolote-110833871629251/?ref=pages_you_manage',
    instagram: 'https://www.instagram.com/centralautolote/',
    gps: '12.098793984837151, -86.23958258283423'
  }
  imgSelected = '';
  movil = false;
  constructor(private modal: ModalController,
              private autoService: AutosService,
              private loadingController: LoadingController,
              private alertController: AlertController,
              private vendidoService: VendidosService,
              private afStorage: AngularFireStorage,
              private actionSheetController: ActionSheetController,
              private iab: InAppBrowser,) { }

  async ngOnInit() {
    this.imgSelected = this.auto.fotoPrincipal;
    this.validarResolucion();
    this.validarNuevo();
    if(this.auto.fotos.length>0){
      this.contador = this.auto.fotos.length;
   
    }
  }


  cerrar(){
    this.modal.dismiss();
  }

  async validarResolucion(){
    if(window.innerWidth <= 880){
      console.log('Celular detectado');
      this.movil = true;
     
    }
    if(window.innerWidth >= 880){
      console.log('Web');
      this.movil = false;

    }
  }

  validarNuevo(){
    if(this.nuevo){
      this.auto.id = this.autoService.crearId();
    }
  }


  async guardar(){
    this.auto.fotoPrincipal = this.auto.fotos[0].img;
    if(!this.nuevo){
      this.autoService.update(this.auto, this.auto.id);
      this.modal.dismiss();
    }
    else{

    this.autoService.add(this.auto);
    this.modal.dismiss();
    
  }

  }
  


  cambiarImagen(i){
    this.imgSelected = i.img;
  }

  
  async selectFile(event){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
       await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        this.subirArchivo();
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
   
  }
  
  async subirArchivo() {
    
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Subiendo archivo...',
  
    });
    await loading.present();
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.afStorage.ref('Autos/' + this.auto.id + '/' + this.contador.toString());
    await referencia.put(archivo);
    referencia.getDownloadURL().subscribe(data =>{
      if(data){
        let img = {
          nombre: this.contador.toString(),
          img: data,
        }
        this.auto.fotos.push(img);
        this.imgSelected = img.img;
      }
    });
    this.limpiarCarga();
    
    loading.dismiss();
  }
  
  limpiarCarga(){
    this.mensajeArchivo = 'No hay un archivo seleccionado';
    this.nombreArchivo = '';
    this.datosFormulario.delete('archivo');
    this.contador += 1;
    
  
  }


  borrarFoto(url){
   
    
    // Create a reference to the file to delete
    var fileRef = this.afStorage.storage.refFromURL(url);
    
    // Delete the file using the delete() method
    fileRef.delete().then(function () {
    
      // File deleted successfully
      console.log("File Deleted")
    }).catch(function (error) {
      // Some Error occurred
    });
      
    let index = this.auto.fotos.findIndex(a => a.img === url);
    this.auto.fotos.splice(index, 1);
    this.autoService.update(this.auto, this.auto.id);
  }


  async borrarAuto(){
    this.autoService.borrar(this.auto.id);
    this.modal.dismiss();
  }

  async borrarFotos(){
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Borrando fotos de la base de datos..',
  
    });
    await loading.present();
      this.auto.fotos.forEach(foto=>{
        var fileRef = this.afStorage.storage.refFromURL(foto.img);
    
        // Delete the file using the delete() method
        fileRef.delete().then(function () {
        
          // File deleted successfully
          console.log("File Deleted")
        }).catch(function (error) {
          console.log("Error al borrar")
        });
      })
      loading.dismiss();
      this.borrarAuto();
  }

  async alertaConfirmarBorrar() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmar!',
      message: 'Esta seguro de eliminar este auto de la base de datos?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',

          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: () => {
           this.borrarFotos();
          }
        }
      ]
    });

    await alert.present();
  }
  async alertaConfirmarVendido() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmar!',
      message: 'Esta seguro de marcar este auto como vendido?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',

          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: () => {
            this.vendido();
          }
        }
      ]
    });

    await alert.present();
  }


  vendido(){
    this.vendidoService.add(this.auto).then(data =>{
      this.borrarAuto();
    })
  }


  async opciones() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Opciones',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Eliminar Foto',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
         this.borrarFoto(this.imgSelected);
         this.imgSelected = this.auto.fotos[0].img;
        }
    
      },
      {
        text: 'Ver en grande',
        
        icon: 'eye',
        handler: () => {
         this.iab.create(this.imgSelected)
        }
    
      },
       {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role, data } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role and data', role, data);
  }

}





