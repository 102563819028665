import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@Component({
  selector: 'app-auto',
  templateUrl: './auto.component.html',
  styleUrls: ['./auto.component.scss'],
})
export class AutoComponent implements OnInit {
  @Input() auto
  datos = {
    whatsapp: '83695594',
    telefono: '+505 83695594',
    facebook: 'https://www.facebook.com/Central-Autolote-110833871629251/?ref=pages_you_manage',
    instagram: 'https://www.instagram.com/centralautolote/',
    gps: '12.098793984837151, -86.23958258283423'
  }
  imgSelected = '';
  movil = false;
  constructor(private modal: ModalController,
    private iab: InAppBrowser,) { }

  ngOnInit() {
    this.imgSelected = this.auto.fotoPrincipal;
    this.validarResolucion();
  }


  cerrar(){
    this.modal.dismiss();
  }

  async validarResolucion(){
    if(window.innerWidth <= 880){
      console.log('Celular detectado');
      this.movil = true;
     
    }
    if(window.innerWidth >= 880){
      console.log('Web');
      this.movil = false;

    }
  }


  cambiarImagen(i){
    this.imgSelected = i.img;
  }

  abrirWhats(){
    this.iab.create('https://wa.me/505' + this.datos.whatsapp);
  }

}
