import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Auto } from '../models/auto';

@Injectable({
  providedIn: 'root'
})
export class VendidosService {

  private autosCollection: AngularFirestoreCollection<any>;

  private autos: Observable<any[]>;


  constructor(public db: AngularFirestore) {
   
    this.autosCollection = db.collection<Auto>('AUTOS-VENDIDOS', (ref)=>
    ref.orderBy('fechaIngreso', 'desc'));

    this.autos = this.autosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a:any) => {
          const data = a.payload.doc.data();
          const key = a.payload.doc.id;
          return { key, ...data };
        });
      })
    );
  }

  getAll() {
    this.autos = this.db.collection<Auto>('AUTOS-VENDIDOS', (ref)=>
    ref.orderBy('fechaIngreso', 'desc')).valueChanges();
    return this.autos;
  }

  crearId(){
    let id = this.db.createId();

    return id;
  }


  update(Cia: Auto, id: string) {
    this.autosCollection = this.db.collection<Auto>('AUTOS-VENDIDOS', (ref)=>
    ref.orderBy('fechaIngreso', 'desc'));
    return this.autosCollection.doc(id).update(Cia);
  }

  add(Cia: Auto) {
    this.autosCollection = this.db.collection<any>('AUTOS-VENDIDOS', (ref)=>
    ref.orderBy('fechaIngreso', 'desc'));
    return this.autosCollection.doc(Cia.id).set(Cia);
  }

  borrar(id) {
    this.autosCollection = this.db.collection<Auto>('AUTOS-VENDIDOS', (ref)=>
    ref.orderBy('fechaIngreso', 'desc'));
    return this.autosCollection.doc(id).delete();
  }
}