import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";

import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";

import { AngularFireStorageModule } from "@angular/fire/storage";
import { environment } from "src/environments/environment";
import { FormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SidemenuAdminComponent } from "./components/sidemenu-admin/sidemenu-admin.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { AutoComponent } from "./components/auto/auto.component";
import { ContactoComponent } from "./components/contacto/contacto.component";
import { QuienesSomosComponent } from "./components/quienes-somos/quienes-somos.component";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CotizarComponent } from "./components/cotizar/cotizar.component";
import { ModalCotizacionComponent } from "./components-admin/modal-cotizacion/modal-cotizacion.component";
import { ModalAutoComponent } from "./components-admin/modal-auto/modal-auto.component";
import { ModalVentaComponent } from "./components-admin/modal-venta/modal-venta.component";

@NgModule({
  declarations: [
    AppComponent,
    SidemenuAdminComponent,
    AutoComponent,
    ContactoComponent,
    QuienesSomosComponent,
    CotizarComponent,
    ModalCotizacionComponent,
    ModalAutoComponent,
    ModalVentaComponent

  ],
  entryComponents: [SidemenuAdminComponent,],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireStorageModule,
    CommonModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot(),
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports: [],
  providers: [
    InAppBrowser,
    DatePipe,
    HttpClient,
    StatusBar,
    SplashScreen,
    IonicStorageModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
