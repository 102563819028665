import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.scss'],
})
export class CotizarComponent implements OnInit {
  form = {
    nombre: '',
    apellido: '',
    correo: '',
    link: '',
    telefono: '',
    notas: '',
    fecha: new Date().toISOString(),
    codigo: ''
  }
  movil = false;
  mostrar = false;
  constructor(private alertController: AlertController,
              private loadingController: LoadingController,
              private cotizacionServ: CotizacionService,
              private iab: InAppBrowser,
              private modal: ModalController,) { }

  ngOnInit() {
    this.validarResolucion();
  }


  async validarResolucion(){
    if(window.innerWidth <= 880){
      console.log('Celular detectado');
      this.movil = true;
      this.mostrar = true;
     
    }
    if(window.innerWidth >= 880){
      console.log('Web');
      this.movil = false;
      this.mostrar = true;
    }
  }

  
  async alerta() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'ios',
      header: 'Informacion enviada con Éxito',
      message: 'En breve serás contactado por alguno de nuestros agentes',
      buttons: ['Aceptar']
    });

    await alert.present();

 
  }

  cerrar(){
    this.modal.dismiss();
  }

  async guardar(){
    const loading = await this.loadingController.create({
      message: "Enviando.."
    });
    await loading.present();
    this.cotizacionServ.add(this.form).then(data =>{
      this.modal.dismiss();
      this.alerta();
      loading.dismiss();
    })
 
  }


  copart(){
    this.iab.create('https://www.copart.com/');
  }

  iaai(){
    this.iab.create('https://www.iaai.com/');
  }



}
