import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },

 
  {
    path: 'configuraciones',
    loadChildren: () => import('./configuraciones/configuraciones.module').then( m => m.ConfiguracionesPageModule)
  },
  
  {
    path: 'autos',
    loadChildren: () => import('./autos/autos.module').then( m => m.AutosPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'venta',
    loadChildren: () => import('./venta/venta.module').then( m => m.VentaPageModule)
  },
  {
    path: 'cotiza',
    loadChildren: () => import('./cotiza/cotiza.module').then( m => m.CotizaPageModule)
  },
  {
    path: 'autos-registrados',
    loadChildren: () => import('./admin/autos-registrados/autos-registrados.module').then( m => m.AutosRegistradosPageModule)
  },
  {
    path: 'solicitud-ventas',
    loadChildren: () => import('./admin/solicitud-ventas/solicitud-ventas.module').then( m => m.SolicitudVentasPageModule)
  },
  {
    path: 'solicitud-cotizacion',
    loadChildren: () => import('./admin/solicitud-cotizacion/solicitud-cotizacion.module').then( m => m.SolicitudCotizacionPageModule)
  },
  {
    path: 'menu-admin',
    loadChildren: () => import('./admin/menu-admin/menu-admin.module').then( m => m.MenuAdminPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
