import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.scss'],
})
export class QuienesSomosComponent implements OnInit {
 @Input() datos;
  constructor(private modal: ModalController,
    private iab: InAppBrowser,) { }

  ngOnInit() {}


    cerrar(){
      this.modal.dismiss();
    }

    abrirFace(){
      this.iab.create(this.datos.facebook);
    }
  
    verEnMapa(){
      this.iab.create('https://www.google.com/maps?q=' + this.datos.gps)
    }
  
    abrirInsta(){
      this.iab.create(this.datos.instagram);
    }
  
    abrirWhats(){
      this.iab.create('https://wa.me/505' + this.datos.whatsapp);
    }
}
